<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { useAppOptionStore } from '@/apps/status/stores/app-option';
import { useAppVariableStore, generateVariables } from '@/shared/stores/app-variable';
import { ProgressFinisher, useProgress } from '@marcoschulte/vue3-progress';
import AppHeader from '@/apps/status/components/Header.vue';
import AppFooter from '@/apps/status/components/Footer.vue';
import router from './router';
import axios from "axios";

const appVariable = useAppVariableStore();
const appOption = useAppOptionStore();
const progresses = [] as ProgressFinisher[];
var isLoading = ref(true);
var hasData = ref(false);

function changeColorSheme(newColorScheme) {
	appOption.appMode = newColorScheme;
	document.documentElement.setAttribute('data-bs-theme', newColorScheme);
	var newVariables = generateVariables();
	appVariable.font = newVariables.font;
	appVariable.color = newVariables.color;
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
	const newColorScheme = event.matches ? "dark" : "light";
	changeColorSheme(newColorScheme);
});

if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
	changeColorSheme("dark");
}

onMounted(() => {
	const fetchData = async () => {
		try {
			const res = await axios.get('https://saci.key2host.com/public/status/');
			if (res.data && Object.keys(res.data).length > 0) {
				appOption.appData = res.data;
				hasData.value = true;
				clearInterval(retryInterval);
				console.log('[K2H API] Data fetched successfully');
			} else {
				console.log('[K2H API] No data fetched: Empty response');
			}
		} catch (error) {
			appOption.appData = null;
			hasData.value = false;
			console.log('[K2H API] Error fetching data:', error);
		}
	};

	fetchData();
	setInterval(fetchData, 10000);
});

router.beforeEach(async (to, from) => {
	isLoading.value = true;
	progresses.push(useProgress().start());
	if (to.params.id && to.path.startsWith('/stats/')) {
		const id = to.params.id;
		const appDataItem = appOption.appData[id];
		if (appDataItem == null) {
			router.replace("/404");
		}
	}
})

router.afterEach(async (to, from) => {
	isLoading.value = false;
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	progresses.pop()?.finish();
})

document.querySelector('body').classList.add('app-init');
</script>

<template>
	<div class="app" v-bind:class="{
		'app-header-menu-search-toggled': appOption.appHeaderSearchToggled,
		'app-content-full-height': appOption.appContentFullHeight,
		'app-content-full-width': true,
		'app-without-header': appOption.appHeaderHide,
		'app-boxed-layout': appOption.appBoxedLayout,
		'app-footer-fixed': appOption.appFooterFixed,
	}">
		<vue3-progress-bar />
		<div v-if="isLoading" class="d-flex justify-content-center align-items-center">
			<div class="position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center"
				style="z-index: 9999;">
				<div class="spinner-border mb-2 text-white" style="width: 3rem; height: 3rem;" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
				<span class="text-white">{{ $t("APP.OTHER.WAIT") }}...</span>
			</div>
		</div>
		<div v-if="!hasData && !isLoading" class="d-flex justify-content-center align-items-center">
			<div class="position-fixed top-0 start-0 w-100 h-100 bg-black opacity-90 d-flex flex-column align-items-center justify-content-center"
				style="z-index: 9999;">
				<div class="mb-2">
					<i class="fa-solid fa-exclamation fa-2xl fa-beat-fade text-white"
						style="--fa-animation-duration: 4s;"></i>
				</div>
				<h2>{{ $t("APP.ERRORS.NODATA.TITLE") }}</h2>
				<span class="text-white">{{ $t("APP.ERRORS.NODATA.DESC.1") }}</span>
				<span class="text-white">{{ $t("APP.ERRORS.NODATA.DESC.2") }}</span>
				<br>
				<small class="text-white text-opacity-25">{{ $t("APP.ERRORS.NODATA.TRYNOTICE") }}</small>
				<div class="fixed-bottom mb-3 text-center">
					<a href="https://www.key2host.com" class="btn btn-outline-theme fw-bold rounded-pill px-3 py-2">{{
						$t("APP.ERRORS.NODATA.BACK") }} <i class="fa fa-arrow-right ms-2 opacity-5"></i></a>
				</div>
			</div>
		</div>
		<app-header v-if="!appOption.appHeaderHide" />
		<div class="app-content" v-bind:class="appOption.appContentClass">
			<router-view v-if="hasData"></router-view>
		</div>
		<app-footer v-if="appOption.appFooter && hasData" />
	</div>
</template>