import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

// BEGIN PINIA
import { createPinia } from "pinia";
app.use(createPinia());
// ENDOF PINIA

// BEGIN VUE3PROGRESS
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress";
import "@marcoschulte/vue3-progress/dist/index.css";
app.use(Vue3ProgressPlugin);
// ENDOF VUE3PROGRESS

// BEGIN PERFECTSCROLLBAR
import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";
app.use(PerfectScrollbarPlugin);
// ENDOF PERFECTSCROLLBAR

// BEGIN MITT
import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
// ENDOF MITT

// BEGIN ROUTER
import router from "./router";
app.use(router);
// ENDOF ROUTER

// BEGIN I18N
import { createI18n } from "vue-i18n";
import de from "@/apps/status/lang/de.json";
import en from "@/apps/status/lang/en.json";
const i18n = createI18n({
  legacy: false,
  locale: "de",
  fallbackLocale: "en",
  messages: {
    de,
    en
  },
});
app.use(i18n);
// ENDOF I18N

// BEGIN BOOTSTRAP
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import CardElement from "@/shared/components/bootstrap/CardElement.vue";
app.component("CardElement", CardElement);
import CardBody from "@/shared/components/bootstrap/CardBody.vue";
app.component("CardBody", CardBody);
import CardHeader from "@/shared/components/bootstrap/CardHeader.vue";
app.component("CardHeader", CardHeader);
import CardFooter from "@/shared/components/bootstrap/CardFooter.vue";
app.component("CardFooter", CardFooter);
import CardGroup from "@/shared/components/bootstrap/CardGroup.vue";
app.component("CardGroup", CardGroup);
import CardImgOverlay from "@/shared/components/bootstrap/CardImgOverlay.vue";
app.component("CardImgOverlay", CardImgOverlay);
import CardExpandToggler from "@/shared/components/bootstrap/CardExpandToggler.vue";
app.component("CardExpandToggler", CardExpandToggler);
// ENDOF BOOTSTRAP

// BEGIN STYLE
import "@/shared/styles/styles.scss";
// ENDOF STYLE

// BEGIN FONTAWESOME
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "@fortawesome/fontawesome-free/scss/v4-shims.scss";
// ENDOF FONTAWESOME

app.mount("#app");

export default i18n;